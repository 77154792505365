import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Col, Form,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateDisplayedLayers } from 'reducers/map'
import { MAIN_CONFIG } from '../../config/config'

const SettingsModal = ({ show, handleClose, popupClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { displayedLayers } = useSelector(state => state.map)
  const layer = useSelector(state => state.chartis.layer_info)

  const onLayerSelect = object => {
    popupClose()
    if (!displayedLayers.find(obj => obj === object)) {
      dispatch(updateDisplayedLayers([...displayedLayers, object]))
    } else {
      dispatch(updateDisplayedLayers(displayedLayers.filter(obj => obj !== object)))
    }
  }

  return (
    <div className={`settings-modal ${show ? 'active' : ''}`}>
      <div className="d-flex justify-content-between">
        <div className="modal-title">{t('Map.mapSettings')}</div>
        <Button
          className="settings-close-button"
          onClick={handleClose}
        >
          <i className="icons-close" aria-hidden="true" />
        </Button>
      </div>
      <Col className="mt-4 h3 p-0">{t('Map.objectsLayers')}</Col>
      <div className="dropdown-divider mb-2 w-100 ml-0" />

      <Col>
        {Object.keys(MAIN_CONFIG.layers).map(currentLayer => (
          <Form.Check
            type="switch"
            label={layer[currentLayer].description}
            id={currentLayer}
            onChange={() => onLayerSelect(currentLayer)}
            checked={displayedLayers.find(el => el === currentLayer)}
            key={currentLayer}
            className="mt-3 settings-check"
          />
        ))}
      </Col>
    </div>
  )
}

SettingsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  popupClose: PropTypes.func.isRequired,
}
export default SettingsModal
