export const MAIN_API = {
  proxy: process.env.REACT_APP_GATEWAY_URL || '',
  api: process.env.REACT_APP_USE_LOCAL_BACKEND
    ? `http://${window.location.hostname}:8000`
    : process.env.REACT_APP_GATEWAY_URL,
  version: '0.0.1.20200408.1',
}

export const MAIN_CONFIG = {
  app_name: 'Hélios',
  access_perm: 'helios::ACCESS',
  layers: {
    patrimoine_solaire_propriete: {
      props: {
        id: 'patrimoine_solaire_propriete',
        type: 'fill',
        'source-layer': 'patrimoine_solaire_propriete',
        paint: {
          'fill-color': '#d75b5b',
        },
      },
    },
    patrimoine_solaire_propriete10: {
      props: {
        id: 'patrimoine_solaire_propriete10',
        type: 'fill',
        'source-layer': 'patrimoine_solaire_propriete10',
        paint: {
          'fill-color': '#d75b5b',
        },
      },
    },
    patrimoine_solaire_propriete15: {
      props: {
        id: 'patrimoine_solaire_propriete15',
        type: 'fill',
        'source-layer': 'patrimoine_solaire_propriete15',
        paint: {
          'fill-color': '#d75b5b',
        },
      },
    },
    patrimoine_solaire_propriete20: {
      props: {
        id: 'patrimoine_solaire_propriete20',
        type: 'fill',
        'source-layer': 'patrimoine_solaire_propriete20',
        paint: {
          'fill-color': '#d75b5b',
        },
      },
    },
    patrimoine_solaire_propriete25: {
      props: {
        id: 'patrimoine_solaire_propriete25',
        type: 'fill',
        'source-layer': 'patrimoine_solaire_propriete25',
        paint: {
          'fill-color': '#d75b5b',
        },
      },
    },
    patrimoine_solaire_voie: {
      props: {
        id: 'patrimoine_solaire_voie',
        type: 'line',
        'source-layer': 'patrimoine_solaire_voie',
        paint: {
          'line-color': '#222222',
          'line-width': 1,
        },
      },
    },
    patrimoine_solaire_quai: {
      props: {
        id: 'patrimoine_solaire_quai',
        type: 'line',
        'source-layer': 'patrimoine_solaire_quai',
        paint: {
          'line-color': '#AAAAAA',
          'line-width': 2,
        },
      },
    },
    patrimoine_solaire_bati: {
      props: {
        id: 'patrimoine_solaire_bati',
        type: 'fill',
        'source-layer': 'patrimoine_solaire_bati',
        paint: {
          'fill-color': '#646464',
        },
      },
    },
  },
}

export const PORTAL_URL = {
  url: process.env.REACT_APP_PORTAL_URL,
}

export default MAIN_API
