import React from 'react'
import PropTypes from 'prop-types'
import {
  Source, Layer,
} from 'react-map-gl'

const ChartisLayer = ({
  mapUrl, sourceLayer, sourceTable, config,
}) => (
  <Source
    id={`${sourceTable}-${sourceLayer}-source`}
    type="vector"
    url={`${mapUrl}/chartis/v2/layer/${sourceTable}/mvt/${sourceLayer}/`}
  >
    <Layer {...config.props} />
  </Source>
)

ChartisLayer.propTypes = {
  mapUrl: PropTypes.string.isRequired,
  sourceLayer: PropTypes.string.isRequired,
  sourceTable: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
}

export default ChartisLayer
