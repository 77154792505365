/* eslint-disable */

import React, { useState } from 'react'
import ReactMapGL from 'react-map-gl'
import { Popup as MapPopup } from 'react-map-gl'
import mapStyleJSON from 'assets/mapstyles/style_empty.json'
import { updateViewport } from 'reducers/map'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import SettingsModal from 'components/Map/SettingsModal'
import ObjectsLayer from 'components/Map/ObjectsLayer'
import PopupContent from 'components/Map/PopupContent'
import Loader from '../components/Loader';

const styles = {
  map: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
  },
  settingButton: {
    padding: 0,
    fontSize: '1.25rem',
    border: '1px solid',
  },
}

const Map = () => {
  const dispatch = useDispatch()
  const { viewport, trackedSource, displayedLayers } = useSelector(state => state.map)
  const [show, setShow] = useState(false)
  const [showPopup, togglePopup] = useState(false)
  const [featureInfoHover, setFeatureInfoHover] = useState(undefined)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const popupClose = () => togglePopup(false)
  const onViewportChange = newViewport => {
    localStorage.setItem('newViewport', JSON.stringify(newViewport))
    dispatch(updateViewport(newViewport))
  }
  const layers = useSelector(state => state.chartis.layer_info)

  const onFeatureClick = e => {
    if (e?.features?.length > 0) {
      setFeatureInfoHover(e)
      togglePopup(true)
      console.log(e)
      setFeatureInfoHover(e)
    }
  }

  return Object.keys(layers).length === 0 ? (
      <Loader />
  ) : (
    <main className="mastcontainer-map">
      <div className="btn-map-setting">
        <Button className="btn-rounded btn-rounded-white" style={styles.settingButton} onClick={handleShow}>
          <i className="icons-admin" aria-hidden="true" />
        </Button>
      </div>
      <SettingsModal show={show} handleClose={handleClose} popupClose={popupClose} />
      <ReactMapGL
        {...viewport}
        width={styles.map.width}
        height={styles.map.height}
        mapStyle={mapStyleJSON}
        onViewportChange={onViewportChange}
        onClick={onFeatureClick}
        clickRadius={2} // Click made easier !
        id="chartis-viz-map"
        getCursor={() => styles.map.cursor}
      >
        {displayedLayers.length !== 0 && <ObjectsLayer sourceLayer="geo" />}
        {showPopup && (<MapPopup
          longitude={featureInfoHover?.lngLat[0]}
          latitude={featureInfoHover?.lngLat[1]}
          closeButton={true}
          captureScroll={true}
          onClose={() => togglePopup(false)}
          className="mapboxgl-hover-custom-popup"
        >{PopupContent({featureInfoHover, layers})}</MapPopup>)}
      </ReactMapGL>
    </main>
  )
}

export default Map
