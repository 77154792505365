import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { MAP_URL } from 'common/Map/const'

import ChartisLayer from 'common/Map/Layers/ChartisLayer'
import { MAIN_CONFIG } from '../../config/config'

function ObjectsLayer({ sourceLayer }) {
  const { displayedLayers } = useSelector(state => state.map)

  return displayedLayers.map(name => (
    <ChartisLayer
      key={name}
      mapUrl={MAP_URL}
      sourceLayer={sourceLayer}
      sourceTable={name}
      config={MAIN_CONFIG.layers[name]}
    />
  ))
}

ObjectsLayer.propTypes = {
  sourceLayer: PropTypes.string.isRequired,
}

export default ObjectsLayer
