import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import TopBar from 'components/TopBar'
import Map from 'views/Map'

import { MAIN_CONFIG } from './config/config'
import { refreshLayerInfo } from './reducers/chartis'

const Home = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(refreshLayerInfo())
  }, [])

  return (
    <>
      <TopBar appName={MAIN_CONFIG.app_name} />
      <Map />
    </>
  )
}

export default Home
