import produce from 'immer'
import { get } from '@osrdata/app_core/dist/requests'

export const GET_LAYER_INFO = 'GET_LAYER_INFO'

const API_URLS = {
  layer_info: '/chartis/v2/info/',
}

const initialState = {
  layer_info: {},
}

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_LAYER_INFO:
        draft.layer_info = action.layer_info
        break
      default:
    }
  })
}

export const refreshLayerInfo = () => async dispatch => {
  const layer = await get(API_URLS.layer_info)
  const layerDict = {}
  Object.values(layer).forEach(value => {
    value.forEach(l => {
      l.fields_details_mapped = {}
      l.fields_details.forEach(field => {
        l.fields_details_mapped[field.name] = field
      })
      layerDict[l.name] = l
    })
  })
  dispatch({
    type: GET_LAYER_INFO,
    layer_info: layerDict,
  })
  return layerDict
}
